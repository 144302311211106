@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

#root:has(.quote-modal.quote-modal-open),
#root:has(.quote-action-modal.quote-action-modal-open),
#root:has(.menu-open) {
    overflow: hidden;
    height: 100dvh;
}

.custom-select {
    background-image: url('https://res.cloudinary.com/dxd5axwvg/image/upload/v1715866220/Icons/select-arrow-icon_kaddze.svg');
    background-size: 1rem;
    -webkit-appearance: none;
}

.select-no-arrow {
    background-image: none!important;
    background-size: 0!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    padding-right: 0.75rem!important;
}

.text-gray {
    color: #6B7280!important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.quote-modal, .quote-modal * {
    text-wrap: pretty!important;
}

.whitespace-nowrap-important {
    white-space: nowrap!important;
}

/* width */
.package-modal-scrollbar::-webkit-scrollbar,
.quote-modal-scrollbar::-webkit-scrollbar {
    transition: all 0.3s ease-in-out;
    width: 0.5rem;
}
 
/* Track */
.package-modal-scrollbar::-webkit-scrollbar-track,
.quote-modal-scrollbar::-webkit-scrollbar-track {
    background: #1d1d1b;
    border-radius: 5px;
}
 
/* Handle */
.package-modal-scrollbar::-webkit-scrollbar-thumb,
.quote-modal-scrollbar::-webkit-scrollbar-thumb {
    background: #F5F5F7;
    border-radius: 5px;
}
 
/* Handle on hover */
.package-modal-scrollbar::-webkit-scrollbar-thumb:hover,
.quote-modal-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #fff;
}








.animate-arrow {
    animation: arrow 1s infinite;
}

@keyframes arrow {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

.animate-arrow-down {
    animation: arrow-down 1s infinite;
}

@keyframes arrow-down {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}


.object-initial {
    object-fit: initial!important;
}

@media (min-width: 768px) {
    .md\:object-initial {
        object-fit: initial!important;
    }
}

.button-new.disabled {
    opacity: 0.5;
    cursor: default;
}

.button-primary {
    background: var(--primary)!important;
    color: var(--primary-grey)!important;
    /* box-shadow: 0px 4px 4px 0px #00000040 inset; */
    transition-duration: 300ms;
}

.button-primary:hover {
    /* box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.30) inset; */
}
.button-blakka {
    background: var(--primary-grey)!important;
    color: var(--primary)!important;
    /* box-shadow: 0px 4px 4px 0px #00000040 inset; */
    transition-duration: 300ms;
}

.button-primary:hover {
    /* box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.30) inset; */
}

.button-secondary {
    background: var(--primary-yellow)!important;
    color: var(--primary-grey)!important;
    /* box-shadow: 0px 4px 4px 0px #00000040 inset; */
    transition-duration: 300ms;
}

.button-secondary:hover {
    /* box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.30) inset; */
}

.button-transparent.hover-effect {
    background-image: linear-gradient(45deg, transparent, transparent), linear-gradient(90deg, var(--secondary-yellow), var(--primary-yellow));
    background-size: 0 2px;
    background-position: 0 100%;
    border-radius: 0%!important;
    background-repeat: no-repeat;
    transition: background-size var(--transition-duration-normal) ease-in-out;

    padding-left: 0!important;
    padding-right: 0!important;
}

.button-transparent.hover-effect {
    margin-left: 1.25rem!important;
    margin-right: 1.25rem!important;
}

.button-transparent.hover-effect:hover {
    background-size: 100% 2px;
    background-position: 0 100%
}

.button-border-transparent {
    border: 1px solid var(--primary)!important;
}

.footer-link-effect {
    background-image: linear-gradient(45deg, transparent, transparent), linear-gradient(90deg, var(--secondary-yellow), var(--primary-yellow));
    background-size: 0 2px;
    background-position: 0 100%;
    border-radius: 0%!important;
    background-repeat: no-repeat;
    transition: background-size var(--transition-duration-normal) ease-in-out;

    padding-left: 0!important;
    padding-right: 0!important;
}

.footer-link-effect:hover {
    background-size: 100% 2px;
    background-position: 0 100%
}

.navigation-link-effect {
    background-image: linear-gradient(45deg, transparent, transparent), linear-gradient(90deg, #fff, #fff);
    background-size: 0 2px;
    background-position: 0 100%;
    border-radius: 0%!important;
    background-repeat: no-repeat;
    transition: background-size var(--transition-duration-normal) ease-in-out;

    padding-left: 0!important;
    padding-right: 0!important;
}

.navigation-link-effect:hover {
    background-size: 100% 2px;
    background-position: 0 100%
}

.navigation-link-active-effect {
    background-size: 100% 2px;
    background-position: 0 100%
}

.text-shadow {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 44)!important;
}

.product-card {
    background: linear-gradient(70.12deg, rgba(255, 255, 255, 0.1) 24.42%, rgba(255, 255, 255, 0) 102.47%);
    box-shadow: 1.5px 3px 22px 0px #0000001A;
}

.tab-buttons-primary {
    background: var(--primary-accent)!important;
}

.tab-buttons-primary button {
    color: var(--primary-grey)!important;
}

.fadeOut {
    animation: fadeOut .5s ease-in-out;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeIn {
    animation: fadeIn .5s ease-in-out;
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeInQuick {
    animation: fadeInQuick var(--transition-duration-normal) ease-in-out;
    opacity: 1;
}

@keyframes fadeInQuick {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeOutQuick {
    animation: fadeOutQuick var(--transition-duration-normal) ease-in-out;
    opacity: 0;
}

@keyframes fadeOutQuick {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* purgecss start ignore */
.progress-animation {
    animation: progress-animation 3s ease-in-out;
    width: 100%;
}

@keyframes progress-animation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
/* purgecss end ignore */

.blur-effect {
    backdrop-filter: blur(15px)!important;
}

.animation-fade-up {
    animation: fade-up var(--transition-duration) var(--transition-style);
}
.animation-fade-down {
    animation: fade-down var(--transition-duration) var(--transition-style);
}
.animation-fade-right {
    animation: fade-right var(--transition-duration) var(--transition-style);
}

.animation-bounce {
    animation: bounce var(--transition-duration-slowest) cubic-bezier(0.39, 0.575, 0.565, 1);
}
 
.custom-tooltip {

}
.custom-tooltip-child {
    display: none;
    opacity: 0;
    animation: fadeTooltipOut 0.3s ease-in-out;
}
.custom-tooltip:hover .custom-tooltip-child {
    display: block;
    opacity: 1;
    animation: fadeTooltipIn 0.3s ease-in-out;
}

@keyframes fadeTooltipIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeTooltipOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


@layer base {
    html {
        min-width: 320px;
        overflow-x: hidden;
    }

    html section {
        /* overflow-x: auto; */
    }

    h1, h2, h3, h4, h5, h6 {
        @apply text-white;
        @apply uppercase;
        font-family: var(--font-alumni_sans)!important;
        text-wrap: initial;
    }

    p, div {
        @apply text-white;
        text-wrap: initial;
        font-family: var(--font-anaheim)!important;
    }

    a {
        @apply text-white;
        font-family: var(--font-anaheim)!important;
    }

    button {
        font-family: var(--font-alumni_sans)!important;
    }

    h1 {
        @apply leading-6 sm:leading-9 md:leading-10;
    }
    h2 {
        @apply leading-6 sm:leading-9 md:leading-10;
    }
    h3 {
        @apply leading-6 sm:leading-9 md:leading-10;
    }
    h4 {
        @apply leading-6 sm:leading-9 md:leading-10;
    }
    h5 {
        @apply leading-6 sm:leading-9 md:leading-10;
    }
    h6 {
        @apply leading-6 sm:leading-9 md:leading-10;
    }


    /********** Range Input Styles **********/
    /*Range Reset*/
    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;

        @apply py-4;
    }
    
    /* Removes default focus */
    input[type="range"]:focus {
        outline: none;
    }
    
    /***** Chrome, Safari, Opera and Edge Chromium styles *****/
    input[type="range"]::-webkit-slider-runnable-track {
        @apply bg-white rounded-lg h-1;
    }
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -0.9rem;
        @apply h-4 w-4 rounded-full -mt-1.5 bg-primary-yellow-400;
    }
    input[type="range"]:focus::-webkit-slider-thumb {
        @apply border-transparent outline-none border-none;
    }

    input[type="range"]::-webkit-range-progress {
        @apply bg-primary-yellow-400 rounded-lg h-1;
    }
    
    /******** Firefox styles ********/
    input[type="range"]::-moz-range-track {        
        @apply bg-white rounded-lg h-1;
    }
    input[type="range"]::-moz-range-thumb {
        @apply h-8 w-8 rounded-full -mt-4 bg-primary-yellow-400;
    }
    input[type="range"]:focus::-moz-range-thumb {
        @apply border-transparent outline-none border-none;
    }
    input[type="range"]::-moz-range-progress {
        @apply bg-primary-yellow-400 rounded-lg h-1;
    }
    /********** Range Input Styles **********/




    /* ANIMATIONS */
    @keyframes fade-right {
        0% {
            /* opacity: 0; */
            opacity: 1;
            transform: translateX(-100px);
        }
        40% {
            /* opacity: 0.6; */
            transform: translateX(40px);
        }
        100% {
            /* opacity: 1; */
            transform: translateX(0px);
        }
    }
    @keyframes fade-up {
        0% {
            /* opacity: 0; */
            opacity: 1;
            transform: translateY(100px);
        }
        40% {
            /* opacity: 0.6; */
            transform: translateY(-40px);
        }
        100% {
            /* opacity: 1; */
            transform: translateY(0px);
        }
    }
    @keyframes fade-down {
        0% {
            /* opacity: 0; */
            opacity: 1;
            transform: translateY(-100px);
        }
        40% {
            /* opacity: 0.6; */
            transform: translateY(40px);
        }
        100% {
            /* opacity: 1; */
            transform: translateY(0px);
        }
    }
    

    @keyframes bounce {
        from, to { transform: scale(1, 1); }
        25% { transform: scale(1.1, 1.1); }        
        50% { transform: scale(0.9, 0.9); }
        75% { transform: scale(1.05, 1.05); }
    }

    /* purgecss start ignore */
    .item {
        @apply z-1 lg:max-w-1/4;
    }
    .item-1 {
        @apply top-1/20 left-1/100 md:-top-1/10 md:left-1/100 lg:top-1/20 lg:-translate-y-1/2 lg:left-1/100;
    }
    .item-2 {
        @apply top-1/2 -translate-y-1/2 left-1/100 md:top-13/20 md:left-1/100 lg:top-1/2 lg:-translate-y-1/2 lg:left-1/100;
    }
    .item-3 {
        @apply bottom-1/20 left-1/100 md:-bottom-28/100 md:left-1/100 lg:-bottom-1/10 lg:-translate-y-1/2 lg:left-1/100;
    }
    .item-4 {
        @apply top-1/20 right-1/100 md:-top-1/10 md:right-1/100 lg:top-1/20 lg:-translate-y-1/2 lg:right-1/100;
    }
    .item-5 {
        @apply top-1/2 -translate-y-1/2 right-1/100 md:top-17/20 md:right-1/100 lg:top-1/2 lg:-translate-y-1/2 lg:right-1/100;
    }
    /* purgecss end ignore */



    .MuiContainer-root.small-spacing {
        padding-left: 25px!important;
        padding-right: 25px!important;
    }
    
    .text-wrap {
        text-wrap: wrap;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 10px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        border: solid 3px transparent;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.3);
        border: solid 5px transparent;
        border-radius: 25px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.4);
    }

    .custom-gradient-border {
        background: rgb(38,104,75);
        background-size: 200%!important;
        background-image: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(29,29,27,1) 15%, rgba(38,104,75,1) 25%, rgba(29,29,27,1) 35%, rgba(38,104,75,1) 50%, rgba(38,104,75,1) 60%, rgba(29,29,27,1) 70%, rgba(38,104,75,1) 80%, rgba(29,29,27,1) 90%, rgba(38,104,75,1) 100%);
        background-position: left;
        transition: background-position 1000ms!important;
    }

    .product-card-gradient {
        background: linear-gradient(70.12deg, rgba(255, 255, 255, 0.1) 24.42%, rgba(255, 255, 255, 0) 102.47%);
    }

    /* OLD */
    /* .custom-gradient-border {
        background: rgb(38,104,75);
        background-size: 200%!important;
        background-image: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(29,29,27,1) 15%, rgba(17, 110, 205, 1) 25%, rgba(29,29,27,1) 35%, rgba(38,104,75,1) 50%, rgba(38,104,75,1) 60%, rgba(29,29,27,1) 70%, rgba(17, 110, 205, 1) 80%, rgba(29,29,27,1) 90%, rgba(38,104,75,1) 100%);
        background-position: left;
        transition: background-position 1000ms!important;
    } */

    .custom-gradient-border-2-colors {
        background: rgb(38,104,75);
        background-size: 220%!important;
        background-image: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(221,174,58,1) 50%, rgba(38,104,75,1) 70%, rgba(221,174,58,1) 100%);
        background-position: left;
        transition: background-position 500ms!important;
    }

    .animating-border {
        animation: animatingBorder 15s infinite alternate-reverse;
    }

    .map-container {
        width: 100%;
        aspect-ratio: 16/6;
    }
    
    .map-wrapper {
        height: 100%;
        width: 100%;
    }

    .swiper-wrapper .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: left;
        transition: background-position 500ms;
        
    }

    .hide-label {
        display: none!important;
    }

    .swiper-wrapper .swiper-slide-active .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: right;
        transition: background-position 500ms;
    }

    .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
        background-size: 280vh;
    }      

    @keyframes animatingBorder {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }

    :root{
        --primary: #F5F5F7;
        --primary-accent: #DBDBDD;
        --primary-grey: #1D1D1B;
        --secondary-grey: #2B2B28;
        --primary-yellow: #a2cd48;
        --secondary-yellow: #ca940b;
        --primary-green: #26684B;
    
        --transition-duration-fast: 0.2s;
        --transition-duration-normal: 0.3s;
        --transition-duration-slow: 0.5s;
        --transition-duration-slowest: 1s;

        --transition-duration: 0.75s;
        --transition-style: cubic-bezier(.53,.28,.26,1);
    }

    .bg-primary-gray-950-to-transparent-right {
        background: linear-gradient(90deg, #1d1d1b 0%, rgba(0,0,0,0) 100%);
    }

    .bg-primary-gray-950-to-transparent-left {
        background: linear-gradient(-90deg, #1d1d1b 0%, rgba(0,0,0,0) 100%);
    }

    .scroll-to-left {
        animation: scrollToLeft 20s infinite linear;
    }

    @keyframes scrollToLeft {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-100% - 5rem));
        }
    }

    .scroll-to-left-slow {
        animation: scrollToLeftSlow 120s infinite linear;
    }

    @keyframes scrollToLeftSlow {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-100% - 5rem));
        }
    }

    .scroll-to-right {
        animation: scrollToRight 20s infinite linear;
    }

    @keyframes scrollToRight {
        0% {
            transform: translateX(calc(-100% - 5rem));
        }
        100% {
            transform: translateX(0);
        }
    }

    .scroll-to-right-slow {
        animation: scrollToRightSlow 50s infinite linear;
    }

    @keyframes scrollToRightSlow {
        0% {
            transform: translateX(calc(-100% - 5rem));
        }
        100% {
            transform: translateX(0);
        }
    }

    .bg-primary-blue {
        background-color: #116ECD;
    }

    .overflow-inherit {
        overflow: inherit!important;
    }

    .image-list .animated-div{
        width: 100%;
        height: 100%;
    }
    .image-list .animated-div img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }

    .link-element{
        padding: 5px 15px!important;
    }

    .flags {
        width: 30px!important;
        object-fit: cover;
    }
    .paper ul {
        display: flex;
    }

    .spacing-sections {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }

    .bg-white-modal {
        background: white!important;
        backdrop-filter: blur(10px);
    }

    .rapport-text-white {
        color: white!important;
    }
    .rapport-text-gray {
        color: gray!important;
    }

    /* tailwinds media queries */
    @media (min-width: 640px) /*sm*/ {}
    @media (min-width: 768px) /*md*/ {
        .MuiContainer-root.small-spacing {
            padding-left: 50px!important;
            padding-right: 50px!important;
        }

        h1, h2, h3, h4, h5, h6 {
            text-wrap: balance;
        }
        
        p, div {
            text-wrap: balance;
        }
    }
    @media (min-width: 1024px) /*lg*/ {}
    @media (min-width: 1280px) /*xl*/ {}
    @media (min-width: 1536px) /*2xl*/ {}

    @media (max-width: 960px) {
        html section div h1:not(.overide-static-font), html div h1:not(.overide-static-font) {
            @apply text-6xl;
        }
        html section div h2:not(.overide-static-font), html div h2:not(.overide-static-font) {
            @apply text-5xl;
        }
        html section div h3:not(.overide-static-font), html div h3:not(.overide-static-font) {
            @apply text-4xl;
        }
        html section div h4:not(.overide-static-font), html div h4:not(.overide-static-font) {
            @apply text-3xl;
        }
        html section div h5:not(.overide-static-font), html div h5:not(.overide-static-font) {
            @apply text-2xl;
        }
        html section div h6:not(.overide-static-font), html div h6:not(.overide-static-font) {
            @apply text-xl;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 230vh;
        }
    }

    @media (max-width: 768px) {
        html section div h1:not(.overide-static-font), html div h1:not(.overide-static-font) {
            @apply text-5xl;
        }
        html section div h2:not(.overide-static-font), html div h2:not(.overide-static-font) {
            @apply text-4xl;
        }
        html section div h3:not(.overide-static-font), html div h3:not(.overide-static-font) {
            @apply text-3xl;
        }
        html section div h4:not(.overide-static-font), html div h4:not(.overide-static-font) {
            @apply text-2xl;
        }
        html section div h5:not(.overide-static-font), html div h5:not(.overide-static-font) {
            @apply text-xl;
        }
        html section div h6:not(.overide-static-font), html div h6:not(.overide-static-font) {
            @apply text-lg;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 230vh;
        }
    }

    @media (max-width: 567px) {
        html section div h1:not(.overide-static-font) {
            @apply text-4xl;
        }
        html section div h2:not(.overide-static-font) {
            @apply text-3xl;
        }
        /* html section div h3:not(.overide-static-font) {
            @apply text-2xl;
        } */
        /* html section div h4:not(.overide-static-font) {
            @apply text-xl;
        } */
        html section div h5:not(.overide-static-font) {
            @apply text-lg;
        }
        html section div h6:not(.overide-static-font) {
            @apply text-base;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 250vh;
        }
    }    


    /* slideDown animation with keyframes */
    @keyframes slideDown {
        0% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
        100% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
    }
    .slideDown {
        animation: slideDown var(--transition-duration-fast) ease-in-out;
    }

    /* slideUp animation with keyframes */
    @keyframes slideUp {
        0% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
    }
    .slideUp {
        animation: slideUp var(--transition-duration-fast) ease-in-out;
    }



    /* Custom Browser compatabilities */
    .safari .team-card {
        width: 90%!important;
        height: 90%!important;
        margin: 0 auto!important;
    }
    .team-card .team-card-img-wrapper {
        width: 100%!important;
        height: 100%!important;
    }

    /* .combined-contact-form textarea ,textarea:focus, select:focus, input:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
        --tw-ring-inset: var(--tw-empty);
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(29,29,27,1) 15%, rgba(38,104,75,1) 25%, rgba(29,29,27,1) 35%, rgba(38,104,75,1) 50%, rgba(38,104,75,1) 60%, rgba(29,29,27,1) 70%, rgba(38,104,75,1) 80%, rgba(29,29,27,1) 90%, rgba(38,104,75,1) 100%)!important;
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
        border-color: rgba(38,104,75,1)!important;
    } */

    textarea,
    textarea::placeholder,
    .contact-form-text input {
        color: white!important;
    }

    .combined-contact-form textarea::placeholder,
    .combined-contact-form .contact-form-text input {
        color: black!important;
        opacity: .42;
    }


    textarea::placeholder,
    .contact-form-text input::placeholder {
        opacity: 0.6!important;
    }
    .local-offer-form-text input::placeholder {
        opacity: 0.6!important;
    }

    .whatsapp-wrapper button, input, optgroup, select{
        color: black!important;
    }

    .textwrap {
        color: black!important;
    }

    .question {
        @apply text-2xl text-white font-semibold cursor-pointer select-none relative pr-5;
    }
    
    .question::before {
        content: '+';
        @apply text-3xl;
        position: absolute;
        right: 0; top: -2px;
    }

    .question.active::before {
        content: '-';
        @apply text-3xl;
        right: 2px;
    }

    .answer {
        @apply transition-all duration-300 origin-top;
    }

    /* .dotted-border {
        background-image:
            linear-gradient(to right, #000 10%, 
            rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 10px 3px;
        background-repeat: repeat-x;
    } */



    .custom-table {
        @apply flex flex-col rounded-lg overflow-hidden border border-primary-gray-950;
    }
    
    .custom-table .header,
    .custom-table .body .row {
        @apply px-3 py-2;
    }

    /* Header */
    .custom-table .header.title-left {
        @apply text-left;
    }
    .custom-table .header.title-right {
        @apply text-right;
    }

    /* Body */
    .custom-table .body .row {
        @apply grid;
    }

    .custom-table .body .row:nth-child(even) {
        @apply bg-primary-gray-950-t-10;
    }

    .custom-table .body .row .cell {
        @apply text-primary-gray-950;
    }
    
}

/* purgecss start ignore */
textarea {
    font-family: 'Blinker', sans-serif!important;
    font-size: 100%;
}
/* purgecss end ignore */

/* Animation paused but interval reset */
/* [data-interval-paused="true"] [data-item-progress] {
    animation-play-state: paused!important;
}
[data-interval-paused="false"] [data-item-progress] {
    animation-play-state: running!important;
}
[data-animate-progress="true"] [data-item-progress] {
    @apply animate-width;
} */

/* Also reset animation state of the progress bar */
.info-blocks[data-interval-paused="false"] [data-animate-progress="true"] [data-item-progress] {
    @apply animate-width;
}

.info-blocks [data-item-active="false"] [data-item-text] {
    @apply max-h-0px;
}

.info-blocks [data-item-active="true"] [data-item-text] {
    @apply max-h-100px;
}


.expandable-animated-blocks[data-interval-paused="false"] [data-animate-progress="true"] [data-item-progress] {
    @apply animate-width;
}

.expandable-animated-blocks [data-item-active="false"] [data-item-text] {
    @apply max-h-0px;
}

.expandable-animated-blocks [data-item-active="true"] [data-item-text] {
    @apply max-h-100px;
}


.slider-with-text[data-interval-paused="true"] [data-item-progress] {
    @apply w-0;
}
.slider-with-text[data-interval-paused="false"] [data-animate-progress="true"] [data-item-progress] {
    @apply w-full animate-width;
}


.shaft-before::before,
.shaft-after::after {
    content: '';
    
    @apply w-0 rotate-0 bg-primary-gray-950 block h-1px absolute top-0 right-0 transition-all duration-700;
}
.shaft-before::before {
    @apply origin-top-right;
}
.shaft-after::after {
    @apply origin-bottom-right;
}

.animated-arrow.submitting .shaft-before::before,
.animated-arrow.submitting .shaft-after::after {
    width: 8px;
}

.animated-arrow.submitting .shaft-before::before {
    transform: rotate(40deg);
}
.animated-arrow.submitting .shaft-after::after {
    transform: rotate(-40deg);
}



.custom-cursor {
    position: fixed;
    top: 0; left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    pointer-events: none;
    z-index: 9999;
    mix-blend-mode: difference;
    padding: 10px;
    display: none;
    justify-content: center; align-items: center;
    background-color: #f5f5f7;

    margin-top: -10px;
    margin-left: -10px;
}